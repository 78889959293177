import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/Header.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/header/LoginPart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/header/LogoPart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StateProvider"] */ "/vercel/path0/src/app/context/StateProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
